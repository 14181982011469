<template>
  <div class="connectUs">
    <Nav />
    <section class="connectUs-box">
      <div class="form-content">
        <h4 class="form-title">
          联系我们
        </h4>
        <h6 class="form-describe">
          加入我们，与我们共同创造一个温馨、安全、充满活力的社交环境
        </h6>
        <form id="contact-form" class="formbox" novalidate>
          <input type="text" v-model="name" name="name" placeholder="你的昵称" class="inputbox" />
          <input class="inputbox" v-model="email" type="text" name="email" placeholder="你的邮箱" @change="validateEmail"
            required />
          <textarea v-model="message" class="textareabox" name="message" placeholder="你的反馈或建议内容" required></textarea>
          <button type="submit" v-if="!loading && name && email && message" class="submitClass">
            <span>提 交</span>
          </button>
          <div class="submitDis" v-else>提交</div>
        </form>
      </div>
    </section>
    <Footer/>
  </div>
</template>

<script>
import Nav from '@/components/Nav'
import Footer from '@/components/Footer'
export default {
  name: "ConnectUs",
  components: { Nav, Footer },
  data() {
    return {
      name: "",
      email: "",
      message: "",
      loading: false
    };
  },
  mounted() {
    // Initialize EmailJS
    emailjs.init("lCvE3WOqbqiit3J3q"); // Replace with your EmailJS user ID

    function debounce(func, wait) {
      let timeout;
      return function () {
        const context = this;
        const args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(context, args);
        }, wait);
      };
    }
    const submitFormDebounced = debounce((event) => {
      event.preventDefault();
      // Send the form data using EmailJS
      emailjs
        .sendForm("service_2pg3jeh", "template_zh4de9a", event.target)
        .then(() => {
          this.loading = false
          this.$message({
            message: "邮件发送成功！",
            type: "success",
            customClass: "message-box",
          });
          // event.target.reset();
        })
        .catch((error) => {
          this.$message({
            message: "邮件发送失败，请重试！",
            type: "warning",
            customClass: "message-box",
          });
        });
    }, 500); // 设置延迟时间为 500 毫秒
    // Form submission handling
    document
      .getElementById("contact-form")
      .addEventListener("submit", (event) => {
        event.preventDefault();
        this.loading = true
        submitFormDebounced(event);
      });
  },
  methods: {
    validateEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = emailRegex.test(this.email);
      if (!isValid) {
        this.$message({
          message: "邮箱格式无效！",
          type: "warning",
        });
        this.email = ''
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.connectUs {
  .connectUs-box {
    padding: 210rem 28rem;
    height: 100vh;
    display: flex;
    align-items: center;
    background: #F7F7F7;

    .form-content {
      width: 688rem;
      height: 660rem;
      background: #FFFFFF;
      border-radius: 30rem;
      padding: 48rem 88rem;
      margin: 0 auto;

      .form-title {
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        font-size: 24rem;
        color: #333333;
        line-height: 28rem;
        text-align: center;
        font-style: normal;
        text-transform: none;
        margin-bottom: 20rem;
      }

      .form-describe {
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        font-size: 16rem;
        color: #999999;
        line-height: 19rem;
        text-align: center;
        font-style: normal;
        text-transform: none;
        margin-bottom: 50rem;
      }

      .formbox {
        .inputbox {
          width: 100%;
          height: 60rem;
          background: #F9F9F9 !important;
          border-radius: 12rem;
          margin-bottom: 30rem;
          padding-left: 18rem;

          font-size: 16rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 19rem;
          font-style: normal;
          text-transform: none;
        }

        .inputbox::placeholder {
          background: #F9F9F9;
          // padding-left: 20rem; /* 设置左内边距为 10rem */
          color: #CCCCCC;
        }

        .inputbox:focus {
          background: #F9F9F9 !important;
          /* 设置输入文本后的背景颜色 */
        }

        // .inputbox::selection {
        //   background: #F9F9F9;
        //   /* 设置高亮背景颜色 */
        //   color: white;
        //   /* 设置高亮文本颜色 */
        // }

        .textareabox {
          width: 100%;
          height: 148rem;
          padding: 18rem;
          background: #F9F9F9;
          font-size: 16rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 19rem;
          font-style: normal;
          text-transform: none;
          border-radius: 12rem;
          margin-bottom: 50rem;
          resize: none;
        }

        .textareabox::placeholder {
          // padding-left: 20rem; /* 设置左内边距为 10rem */
          color: #CCCCCC;
        }

        .submitClass {
          cursor: pointer;
          width: 512rem;
          height: 60rem;
          background: #333333;
          border-radius: 16rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          font-size: 16rem;
          color: #FFFFFF;
          line-height: 60rem;
          text-align: center;

          span {
            background: -webkit-linear-gradient(180deg, rgba(235, 255, 0, 1) 0%, #00F284 100%);

            /* Chrome, Safari */
            background: linear-gradient(180deg, #EBFF00 0%, #00F284 100%);
            /* 标准语法 */
            -webkit-background-clip: text;
            /* Chrome, Safari */
            background-clip: text;
            -webkit-text-fill-color: transparent;
            /* Chrome, Safari */
            color: transparent;
            /* 兼容不支持background-clip的浏览器 */
          }
        }


        .submitDis {
          cursor: not-allowed;
          width: 512rem;
          height: 60rem;
          border-radius: 16rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          font-size: 16rem;
          background: #CCCCCC;
          color: #FFFFFF;
          line-height: 60rem;
          text-align: center;
          font-style: normal;
          text-transform: none;
        }
      }
    }
  }
}
</style>
